import React from 'react';
import './rajyog.css';
import { useIntl } from "react-intl";

export default function Grid({matrix, showTitle = true, className}) {
    const { formatMessage } = useIntl();
    console.log("MATRIX", matrix, showTitle);
    return (
        <div className={`row ${className ? className : ''}`}>
            <div className='display-6 text-center'>{ typeof(showTitle) !== 'boolean' ? showTitle : formatMessage({id: 'grid.title'}) }</div>
            <hr/>
            <table className='matrix-table'>
                <tbody>
                {matrix.map((row, i) => {
                    return (
                        <tr key={i}>
                            {row.map((col, j) => {
                                return <td key={i+j}><span className='display-3'>{col}</span></td>
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
}
