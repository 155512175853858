const PersonalityDesc = {
    1: {
        "en": [
            {
                "title": "Confident Presence",
                "points": [
                    "Your first impression exudes confidence and assertiveness."
                ]
            },
            {
                "title": "Emotionally Engaging",
                "points": [
                    "You project the demeanor of a person who is highly approachable, quickly establishing meaningful connections.",
                    "Your openness in expressing thoughts contributes to a warm presence among others."
                ]
            },
            {
                "title": "Sense of Humor",
                "points": [
                    "Alongside, your mastery in humor adds a delightful dimension to your personality.",
                    "Your humor resonates well, creating a positive atmosphere."
                ]
            },
            {
                "title": "Versatile Knowledge",
                "points": [
                    "In addition, you excel not only in humor but also in acquiring knowledge across diverse fields.",
                    "Your broad spectrum of knowledge showcases versatility and depth."
                ]
            },
            {
                "title": "Impressive First Meetings",
                "points": [
                    "Your ability to leave a positive impression is evident even in initial encounters.",
                    "The recognition extends, affirming your unique and distinctive personality."
                ]
            }
        ],
        "hi": [
            {
                "title": "आत्मविश्वासपूर्ण उपस्थिति",
                "points": [
                    "आपका पहला इम्प्रेशन आत्मविश्वास और स्पष्टता से भरा हुआ है।"
                ]
            },
            {
                "title": "भावनात्मक संलग्नता",
                "points": [
                    "आप एक ऐसे व्यक्ति की भावनाओं को प्रकट करते हैं जो बड़े ही संबोधनीय हैं, जल्दी ही महत्वपूर्ण संबंध स्थापित कर लेते हैं।",
                    "आपकी खुलेआम विचारों को व्यक्त करने की क्षमता एकमात्र है, जो अन्य लोगों के बीच एक गर्म मौजूदगी बनाती है।"
                ]
            },
            {
                "title": "हँसोड़पन - भावना (Sense of Humor)",
                "points": [
                    "आप एक ऐसे व्यक्ति की भावनाओं को प्रकट करते हैं जो बड़े ही संबोधनीय हैं, जल्दी ही महत्वपूर्ण संबंध स्थापित कर लेते हैं।",
                    "आपकी खुलेआम विचारों को व्यक्त करने की क्षमता एकमात्र है, जो अन्य लोगों के बीच एक गर्म मौजूदगी बनाती है।"
                ]
            },
            {
                "title": "बहुक्षेत्रीय ज्ञान",
                "points": [
                    "इसके अलावा, आप केवल हास्य में ही नहीं, बल्कि विभिन्न क्षेत्रों में ज्ञान अर्जित करने में भी अग्रणी हैं।",
                    "आपका ज्ञान का ब्रॉड स्पेक्ट्रम विविधता और गहराई को प्रदर्शित करता है।"
                ]
            },
            {
                "title": "प्रभावशाली पहली मुलाकातें:",
                "points": [
                    "आपकी क्षमता कि आप पहले ही मुलाकातों में एक सकारात्मक प्रभाव छोड़ सकते हैं स्पष्ट है।",
                    "पहचान बढ़ती है, जिससे आपकी अद्वितीयता और विशिष्टता की पुनराधारिति होती है।"
                ]
            }
        ]
    },
    2: {
        "en": [
            {
                "title": "Communication Style",
                "points": [
                    "Your first impression gives off an easy-going and approachable vibe.",
                ]
            },
            {
                "title": "Flexibility and Compatibility:",
                "points": [
                    "Your flexible nature is evident, allowing people to share various experiences with you.",
                    "Whether in office meetings or social gatherings, your adaptable nature makes interactions enjoyable."
                ]
            },
            {
                "title": "Friendship Dynamics:",
                "points": [
                    "Being a friend, people can share their emotions with you, creating a quick sense of trust."
                ]
            },
            {
                "title": "Maintaining Secrets:",
                "points": [
                    "Your demeanor suggests that you are trustworthy and won't disclose others' secrets easily.",
                    "As a result, people tend to trust you quickly, and the confidence in your character is established promptly."
                ]
            }
        ],
        "hi": [
            {
                "title": "संवाद शैली:",
                "points": [
                    "आप अपने विचारों को खुलकर साझा करते हैं, जिससे दूसरों को दिल के मामलों पर चर्चा करने में आसानी होती है।"
                ]
            },
            {
                "title": "विश्वासनीयता:",
                "points": [
                    "लोग आप पर जल्दी विश्वास करने और आपसे खुलकर बातें करने में आसानी महसूस करते हैं, जल्दी ही विश्वास और संबंध बनते हैं।"
                ]
            },
            {
                "title": "लचीलापन और समर्पण:",
                "points": [
                    "आपकी लचीली प्रकृति स्पष्ट है, जिससे लोग आपके साथ विभिन्न अनुभव साझा कर सकते हैं।",
                    "कार्यालय की मीटिंग्स से लेकर सामाजिक सभागमनों में, आपकी अनुकूल प्रकृति बातचीत को आनंददायक बनाती है।"
                ]
            },
            {
                "title": "मित्रता के गतिविधियाँ:",
                "points": [
                    "दोस्त बनने के रूप में, लोग आपसे अपनी भावनाओं को साझा कर सकते हैं, जिससे विश्वास की त्वरित भावना बनती है।"
                ]
            },
            {
                "title": "रहस्य रखना:",
                "points": [
                    "आपका व्यवहार सुझाता है कि आप विश्वासनीय हैं और दूसरों के रहस्यों को आसानी से नहीं खोलेंगे।",
                    "इस परिणामस्वरूप, लोग आप पर जल्दी विश्वास करते हैं, और आपके व्यक्तित्व में आत्मविश्वास त्वरित होता है।"
                ]
            }
        ]
    },
    3: {
        "en": [
            {
                "title": "Understanding Personality Number Three:",
                "points": [
                    "People with Personality Number Three tend to leave a strong first impression due to their outgoing and charming nature.",
                    "They are often seen as sociable and attractive, making them popular among different social groups.",
                    "For example, in a professional setting, they might easily strike up conversations with colleagues from various departments.",
                    "Similarly, in social gatherings, they effortlessly mingle with people from different backgrounds and interests."
                ]
            },
            {
                "title": "Building Quick Connections:",
                "points": [
                    "Individuals with Personality Number Three have a knack for quickly building connections with others.",
                    "Their friendly demeanor and ability to make others feel comfortable lead to fast friendships and social invitations.",
                    "For instance, they might find themselves invited to events and parties by people they've only just met due to their engaging personality.",
                    "However, while their initial charm may draw people in, they might need to be mindful of not overwhelming others with excessive talking."
                ]
            },
            {
                "title": "Long-Term Impressions and Trustworthiness:",
                "points": [
                    "While Personality Number Threes excel at making a positive first impression, maintaining long-term respect and trustworthiness is essential.",
                    "Their talkative nature might initially be seen as endearing, but over time, it could lead to a loss of respect if not balanced with listening.",
                    "Therefore, it's crucial for them to strike a balance between being sociable and being good listeners.",
                    "For instance, in professional settings, they might need to ensure they're not dominating conversations but also actively listening to others' ideas and opinions."
                ]
            },
            {
                "title": "Impressing in Interviews:",
                "points": [
                    "In interviews, individuals with Personality Number Three often shine due to their confident and engaging demeanor.",
                    "They have a natural ability to make others feel comfortable and leave a lasting positive impression.",
                    "Their adaptability and charm make them stand out as strong candidates.",
                    "For example, they might excel in group interviews where they can showcase their ability to connect with different personalities."
                ]
            }
        ],
        "hi": [
            {
                "title": "व्यक्तित्व संख्या तीन को समझना:",
                "points": [
                    "व्यक्तित्व संख्या तीन वाले लोगों के बारे में उनकी बातचीती और आकर्षक प्रकृति के कारण उनका पहला प्रभाव मजबूत होता है।",
                    "उन्हें अक्सर सामाजिक और आकर्षक माना जाता है, जिससे वे विभिन्न सामाजिक समूहों में लोकप्रिय होते हैं।",
                    "उदाहरण के रूप में, एक पेशेवर स्थिति में, वे अक्सर विभिन्न विभागों के सहयोगियों के साथ बातचीत कर सकते हैं।",
                    "उसी तरह, सामाजिक जुटावों में, वे अलग-अलग पृष्ठभूमियों और रुचियों वाले लोगों के साथ आसानी से मिल जाते हैं।"
                ]
            },
            {
                "title": "तेजी से कनेक्शन बनाना:",
                "points": [
                    "व्यक्तित्व संख्या तीन वाले व्यक्तियों के पास अन्य लोगों के साथ तेजी से कनेक्शन बनाने की क्षमता होती है।",
                    "उनकी मित्रवत प्रवृत्ति और दूसरों को आरामदायक महसूस कराने की क्षमता के कारण तेजी से दोस्तियाँ और सामाजिक निमंत्रण होते हैं।",
                    "उदाहरण के तौर पर, उन्हें अपनी प्रभावी प्रकृति के कारण लोगों के इवेंट्स और पार्टियों में नजदीक से आमंत्रित किया जा सकता है।",
                    "हालांकि, जबकि उनकी पहली आकर्षणीयता लोगों को आकर्षित कर सकती है, तो वे अधिक बोलने से दूसरों को अतिरिक्त चिंता नहीं कराना चाहिए।"
                ]
            },
            {
                "title": "दीर्घकालिक प्रभाव और विश्वसनीयता:",
                "points": [
                    "हालांकि, व्यक्तित्व संख्या तीन अच्छे पहले प्रभाव को बनाने में सफल होते हैं, लंबे समय तक सम्मान और विश्वसनीयता बनाए रखना महत्वपूर्ण है।",
                    "उनकी बातचीती प्राकृतिक रूप से प्रिय लग सकती है, लेकिन समय के साथ यदि सुनने का संतुलन नहीं किया गया है तो इससे सम्मान की कमी हो सकती है।",
                    "इसलिए, उन्हें मित्रवत प्रवृत्ति और अच्छे सुनने के बीच संतुलन बनाए रखने के लिए महत्वपूर्ण है।",
                    "उदाहरण के रूप में, पेशेवर स्थितियों में, वे यह सुनिश्चित करने की आवश्यकता हो सकती है कि वे बातचीत को नहीं ज़बरदस्त कर रहे हैं, लेकिन दूसरों की विचारों और रायों को भी सक्रिय रूप से सुन रहे हैं।"
                ]
            },
            {
                "title": "साक्षात्कार में प्रभाव डालना:",
                "points": [
                    "साक्षात्कार में, व्यक्तित्व संख्या तीन वाले व्यक्तियों को अपने आत्मविश्वास और जुजारू डीमीनर के कारण अक्सर चमकाने मिलते हैं।",
                    "उनकी प्राकृतिक क्षमता दूसरों को आरामदायक महसूस कराने में सहायक होती है और एक धाराप्रवाह पॉइंट पर छोड़ जाती है।",
                    "उनकी अनुकूलता और आकर्षण उन्हें मजबूत उम्मीदवारों के रूप में अलग करती हैं।",
                    "उदाहरण के रूप में, वे विभिन्न व्यक्तित्वों के साथ जुड़ने की उनकी क्षमता को दिखाने के लिए समूह साक्षात्कारों में उत्कृष्टता प्रदर्शित कर सकते हैं।"
                ]
            }
        ]        
    },
    4: {
        "en": [
            {
                "title": "Exploring Personality Number Four:",
                "points": [
                    "Individuals with Personality Number Four are characterized by their practicality and no-nonsense approach.",
                    "They prefer to engage in conversations that have substance and avoid superficial or frivolous topics.",
                    "For example, in a group discussion, they might steer the conversation towards actionable solutions rather than dwelling on hypothetical scenarios.",
                    "Their communication style is clear and concise, making it easy for others to understand their point of view.",
                    "They have a strong adherence to rules and regulations, both in professional and personal contexts.",
                    "For instance, they might strictly follow company policies or household rules without exceptions.",
                    "Others may perceive them as somewhat rigid or stubborn due to their firm beliefs and adherence to principles.",
                    "However, this steadfastness also earns them respect for their consistency and reliability.",
                    "In decision-making, they rely heavily on logic and evidence, preferring tangible facts over abstract ideas.",
                    "For example, they might conduct thorough research before making important choices, ensuring they have all the necessary information."
                ]
            }
        ],
        "hi": [
            {
                "title": "व्यक्तित्व संख्या चार के विशेषताएँ:",
                "points": [
                    "प्रैक्टिकलता और बेकार बातों का न ध्यान देने वाला दृष्टिकोण:",
                    "व्यक्तित्व संख्या चार वाले व्यक्तियों को उनकी जीवन में प्रैक्टिकल दृष्टिकोण के लिए जाना जाता है।",
                    "वे तथ्यात्मक परिणामों पर ध्यान केंद्रित करने का प्राथमिकता देते हैं और उनकी बातचीत में उपस्थिति की कमी वाले विषयों से बचते हैं।",
                    "उदाहरण के लिए, वे सामूहिक चर्चा में कार्यवाही के समाधान की ओर ध्यान केंद्रित कर सकते हैं बजाय अभ्यासवादी स्थितियों पर ध्यान केंद्रित करने की।",
                    "स्पष्ट और संक्षेप्त आचरण:",
                    "उनकी संचार शैली को स्पष्टता और संक्षेप्तता के रूप में वर्णित किया जाता है।",
                    "वे अपने विचारों को स्पष्ट रूप से व्यक्त करते हैं, जिससे दूसरों को उनके दृष्टिकोण को समझने में आसानी होती है।",
                    "नियमों और विनियमों का पालन:",
                    "व्यक्तित्व संख्या चार वाले व्यक्तियों का नियमों और विनियमों के प्रति मजबूत आस्था होती है, चाहे वे पेशेवर जीवन में हों या व्यक्तिगत संदर्भों में।",
                    "उदाहरण के लिए, वे कंपनी की नीतियों या घरेलू नियमों का दृढ़ता से पालन करते होंगे।",
                    "दृढ़ और जिद्दी मान्यताएं:",
                    "उनके सिद्धांतों और मजबूत विश्वासों के पालन के कारण दूसरों को वे कभी-कभार थोड़े जिद्दी या अड़ियल माना जा सकता है।",
                    "हालांकि, यह दृढ़ता भी उन्हें उनकी स्थिरता और विश्वसनीयता के लिए सम्मान प्राप्त करती है।",
                    "तर्क और साक्ष्य पर आधारितता:",
                    "निर्णय लेने के प्रक्रियाओं में, व्यक्तित्व संख्या चार वाले व्यक्तियों को तर्क और साक्ष्य पर भरोसा होता है।",
                    "वे अभिकल्पना या अटकलों की बजाय तथ्यात्मक जानकारी को प्राथमिकता देते हैं।",
                    "उदाहरण के लिए, वे महत्वपूर्ण निर्णय लेने से पहले विस्तृत अनुसंधान कर सकते हैं ताकि उन्हें सभी महत्वपूर्ण तथ्य मिल सकें।"
                ]
            }
        ]        
    },
    5: {
        "en": [
            {
                "title": "Initial Impression and Sociability:",
                "points": [
                    "Personality Number Five leaves a first impression of being fun-loving and dynamic.",
                    "They are perceived as sincere and versatile individuals.",
                    "People of all age groups, from children to the elderly, enjoy their company.",
                    "Their cheerful nature and ability to engage with different age groups make them likable."
                ]
            },
            {
                "title": "Knowledge and Humor:",
                "points": [
                    "Individuals with Personality Number Five possess a good deal of knowledge about worldly matters, business, and practical affairs.",
                    "They often share this knowledge with humor, making discussions lively and enjoyable.",
                    "Others perceive them as knowledgeable and appreciate their ability to make learning enjoyable."
                ]
            },
            {
                "title": "Perceived Benefits of Association:",
                "points": [
                    "People may hesitate to get too close to Personality Number Five as they may not see direct benefits from the association.",
                    "While they appreciate the individual's resources and knowledge, they may not always perceive a clear advantage in forming deeper connections.",
                    "This perception can lead to a larger portion of surface-level interactions compared to deep connections in relationships."
                ]
            },
            {
                "title": "Lively Behavior and Relationships:",
                "points": [
                    "Those who genuinely love Personality Number Five are drawn to their lively and jovial behavior.",
                    "Relationships with them often maintain a ratio of 20% deep connections to 80% surface-level interactions.",
                    "Others appreciate them primarily for their fun-loving nature and entertaining personality."
                ]
            },
            {
                "title": "Balancing Depth and Sociability:",
                "points": [
                    "It's important for Personality Number Five to strike a balance between their sociable nature and the depth of their interactions.",
                    "They should ensure they are not just seen as entertaining companions but also as individuals with depth and sincerity.",
                    "Developing deeper connections while maintaining their sociable demeanor can enhance the quality of their relationships."
                ]
            }
        ],
        "hi": [
            {
                "title": "पहले प्रभाव और मिलनसारता:",
                "points": [
                    "व्यक्तित्व संख्या पांच का पहला प्रभाव मजेदार और गतिशील होता है।",
                    "उन्हें सच्चे और बहुमुखी होने के रूप में प्राप्त किया जाता है।",
                    "बच्चे से लेकर बुजुर्ग तक, सभी आयु समूह के लोग उनकी कंपनी का आनंद लेते हैं।",
                    "उनकी आनंदमय स्वभाव और विभिन्न आयु समूहों के साथ बातचीत में शामिल होने की क्षमता उन्हें पसंदीदा बनाती है।"
                ]
            },
            {
                "title": "ज्ञान और हास्य:",
                "points": [
                    "व्यक्तित्व संख्या पांच के व्यक्तियों के पास दुनियावी मामलों, व्यापार और व्यावसायिक मामलों के बारे में बहुत ज्ञान होता है।",
                    "वे अक्सर इस ज्ञान को हास्य के साथ साझा करते हैं, जिससे बातचीत जीवंत और आनंदमय होती है।",
                    "दूसरे उन्हें ज्ञानी मानते हैं और उनकी क्षमता की सराहना करते हैं कि वे शिक्षा को आनंदमय बना सकते हैं।"
                ]
            },
            {
                "title": "संगठन के लाभ का अनुभव:",
                "points": [
                    "लोग व्यक्तित्व संख्या पांच के पास अधिक संबंधित होने के लाभ को देखकर बहुत कुछ आगे नहीं बढ़ते।",
                    "वे व्यक्ति के संसाधनों और ज्ञान की सराहना करते हैं, लेकिन गहरे संबंध बनाने में सीधा लाभ नहीं देखते हैं।",
                    "इस धारणा के कारण रिश्तों में सतही संवादों की तुलना में गहरे संबंधों की अधिकतम हिस्से होते हैं।"
                ]
            },
            {
                "title": "उत्साही व्यवहार और रिश्ते:",
                "points": [
                    "जो वास्तव में व्यक्तित्व संख्या पांच को प्यार करते हैं, वे उनके उत्साही और हंसमुख व्यवहार को खींचते हैं।",
                    "उनके साथ रिश्ते अक्सर 20% गहरे संबंधों के साथ 80% सतही संवादों की साझेदारी का अनुभव करते हैं।",
                    "दूसरों को प्रमुख रूप से उनके मस्त मौला और मनोरंजक व्यक्तित्व के लिए प्रशंसा होती है।"
                ]
            },
            {
                "title": "गहराई और संगीता के संतुलन की आवश्यकता:",
                "points": [
                    "व्यक्तित्व संख्या पांच के लिए महत्वपूर्ण है कि वे अपने सामाजिक स्वभाव और रिश्तों की गहराई के बीच संतुलन स्थापित करें।",
                    "उन्हें सुनिश्चित करना चाहिए कि वे केवल मनोरंजनक साथी के रूप में ही देखे जाएं, बल्कि एक व्यक्ति के रूप में भी गहराई और सच्चाई के साथ।",
                    "अपने सामाजिक व्यवहार को बनाए रखते हुए गहरे संबंधों को विकसित करना उनके रिश्तों की गुणवत्ता को बढ़ा सकता है।"
                ]
            }
        ]
    },
    6: {
        "en": [
            {
                "title": "First Impression and Family-Centricity:",
                "points": [
                    "Personality Number Six gives off a simple, sober, and family-loving first impression.",
                    "Most people would perceive you as beautiful because Personality Number Six signifies being top-notch.",
                    "You come across as a straightforward, serious, and family-oriented individual, which people appreciate the most.",
                    "Your behavior signals a special attention towards family, yet you prefer not to meddle in any family conflicts."
                ]
            },
            {
                "title": "Your Trustworthy Impression:",
                "points": [
                    "When needed, people consider you as the go-to person.",
                    "The impression of your personality tends to be positive among most individuals.",
                    "If your personality number is six, please comment and let me know."
                ]
            }
        ],
        "hi": [
            {
                "title": "प्रथम प्रभाव और परिवारिकता:",
                "points": [
                    "पर्सनालिटी नंबर सिक्स का पहला प्रभाव सरल, गंभीर, और परिवार के प्रेमी होने की भावना देता है।",
                    "आपको ज्यादातर लोग सुंदर समझेंगे क्योंकि पर्सनालिटी नंबर सिक्स आपको टॉप क्लास में देखना पसंद करते हैं।",
                    "आप एक सरल, गंभीर, और परिवार केंद्रित व्यक्ति की भावना देते हैं, जिसे लोग सबसे अधिक प्रिय करते हैं।",
                    "आपका व्यवहार परिवार के प्रति विशेष ध्यान देने का संकेत देता है, लेकिन आप किसी भी परिवारिक विवाद के खिलाफ नहीं जाना चाहते।"
                ]
            },
            {
                "title": "आपका विश्वासप्राप्त इम्प्रेशन:",
                "points": [
                    "जब आपको जरूरत होती है, तो लोग आपको पहले व्यक्ति मानते हैं।",
                    "आपके व्यक्तित्व का इम्प्रेशन लोगों के अधिकांश लोगों के अंदर सकारात्मक होता है।",
                    "अगर आपका पर्सनालिटी नंबर सिक्स है, तो कृपया टिप्पणी करें और मुझे बताएं।"
                ]
            }
        ]
    },
    7: {
        "en": [
            {
                "title": "Two Facets of First Impression:",
                "points": [
                    "Personality Number Seven's first impression can be perceived in two ways: one, as someone who is quite confused and easily influenced, and the other, as someone very knowledgeable or spiritually inclined.",
                    "Some might see you as someone who lacks direction and can be easily manipulated, while others might view you as highly knowledgeable or spiritually inclined, engaging in extensive research."
                ]
            },
            {
                "title": "Categorizing Your Impression:",
                "points": [
                    "In Category A, you might exhibit traits such as being shy, feeling old beyond your years, or engaging in conversations beyond your age.",
                    "In Category B, you might demonstrate qualities like being knowledgeable, spiritually inclined, or engaging in deep research.",
                    "Please comment once you decide whether you resonate more with Category A or Category B. Let me know how people perceive you!"
                ]
            }
        ],
        "hi": [
            {
                "title": "पहले प्रभाव की दो पहलुओं में:",
                "points": [
                    "पर्सनालिटी नंबर सात का पहला प्रभाव दो तरह से माना जा सकता है: पहला, किसी को बहुत ही भ्रमित और आसानी से प्रभावित माना जाता है, और दूसरा, किसी बहुत ही ज्ञानवान या आध्यात्मिक ओरिएंटेड माना जाता है।",
                    "कुछ लोग आपको उन्मुख और आसानी से प्रभावित होने वाला व्यक्ति मानते हैं, जबकि दूसरे आपको बहुत ही ज्ञानवान या आध्यात्मिक ओरिएंटेड, व्यापक अनुसंधान में व्यस्त होते हुए देखते हैं।"
                ]
            },
            {
                "title": "आपके प्रभाव की श्रेणीकरण:",
                "points": [
                    "श्रेणी ए में, आप शर्मिला होने, अपनी उम्र से ज्यादा बड़ी बातें करने या अपनी उम्र से अधिक प्रभावित होने जैसी गुणवत्ताएं प्रदर्शित कर सकते हैं।",
                    "श्रेणी ब में, आप ज्ञानी, आध्यात्मिक ओरिएंटेड, या गहरे अनुसंधान में लिप्त होने जैसी गुणवत्ताएं दिखा सकते हैं।",
                    "एक बार जब आप तय कर लें कि आप श्रेणी ए या श्रेणी ब के साथ अधिक संबंधित हैं, तो कृपया टिप्पणी करें। मुझे बताएं कि लोग आपको कैसे महसूस करते हैं!"
                ]
            }
        ]                
    },
    8: {
        "hi": [
            {
                "title": "Resourceful Impression:",
                "points": [
                    "If your personality number is eight, your first impression is often that of a resourceful person.",
                    "People tend to see you as someone who has solutions to every problem, and they may even reach out to you for advice on small matters like which phone or laptop to buy within a budget, or which car to choose.",
                    "However, Personality Number Eight might also be perceived as someone who experiences tension, stress, and overthinking."
                ]
            },
            {
                "title": "Hardworking and Reliable Nature:",
                "points": [
                    "Personality Number Eight is often seen as hardworking and resourceful.",
                    "People may rely on you for solutions and trust you, but there could be a tendency for such individuals to not fulfill commitments promptly.",
                    "Even if plans change frequently, people find it hard to lose trust in you.",
                    "Please comment if you resonate more with the hardworking and reliable nature or the tendency to not fulfill commitments promptly."
                ]
            }
        ],
        "en": [
            {
                "title": "Impression of Resourcefulness:",
                "points": [
                    "If your personality number is eight, the first impression people get of you is that of a resourceful individual.",
                    "They perceive you as someone who has solutions to every problem, and they may even seek your advice on trivial matters like which phone or laptop to buy within a budget, or which car to choose.",
                    "However, Personality Number Eight might also be seen as someone who experiences tension, stress, and overthinking."
                ]
            },
            {
                "title": "Hardworking and Reliable Nature:",
                "points": [
                    "Personality Number Eight is often seen as hardworking and reliable.",
                    "People may rely on you for solutions and trust you, but there could be a tendency for such individuals to not fulfill commitments promptly.",
                    "Even if plans change frequently, people find it hard to lose trust in you.",
                    "Please comment if you resonate more with the hardworking and reliable nature or the tendency to not fulfill commitments promptly."
                ]
            }
        ]                
    },
    9: {
        "en": [
            {
                "title": "Perceived Respect and Connection:",
                "points": [
                    "If your personality number is nine, people initially perceive you with respect, seeing you as someone who cannot be taken lightly.",
                    "This individual often gets the opportunity to connect with others and make a significant difference in the world.",
                    "As you grow older, you tend to form connections with people who are inclined to help others, such as those involved in charity work or NGOs."
                ]
            },
            {
                "title": "Sociable and Guardian Personality:",
                "points": [
                    "Personality Number Nine is also seen as a sociable person who commands respect in society.",
                    "People may turn to you for guidance in their lives, seeing you as a guardian figure.",
                    "Additionally, your charming personality adds to the appeal of Personality Number Nine."
                ]
            }
        ],
        "hi": [
            {
                "title": "उत्कृष्ट सम्मान और संबंध:",
                "points": [
                    "अगर आपका व्यक्तित्व नंबर नौ है, तो लोग प्रारंभ में आपका सम्मान करते हैं, आपको किसी को भी हल्के में नहीं लेते हैं।",
                    "यह व्यक्ति अक्सर दूसरों से जुड़ने और दुनिया में महत्वपूर्ण बदलाव लाने का मौका प्राप्त करता है।",
                    "जैसे ही आप बड़े होते हैं, आपको उन लोगों से जुड़ने का मौका मिलता है जो दूसरों की मदद करने को इच्छुक होते हैं, जैसे कि जो चैरिटी कार्य या एनजीओ में शामिल होते हैं।"
                ]
            },
            {
                "title": "सामाजिक और संरक्षक व्यक्तित्व:",
                "points": [
                    "व्यक्तित्व नंबर नौ को भी सामाजिक व्यक्ति के रूप में देखा जाता है जो समाज में सम्मान प्राप्त करता है।",
                    "लोग आपसे अपने जीवन में मार्गदर्शन के लिए मुड़ते हैं, आपको एक संरक्षक चित्र में देखते हैं।",
                    "इसके अतिरिक्त, आपका आकर्षक व्यक्तित्व व्यक्तित्व नंबर नौ को और भी प्रिय बनाता है।"
                ]
            }
        ]                
    }
};

export default PersonalityDesc;