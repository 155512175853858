import React, { useEffect, useState } from 'react'
import './rajyog.css';
import { rajyog } from '../helper/rajyog';
import {Card, ListGroup } from 'react-bootstrap/';
import {db} from '../firebase';
import { doc, setDoc, getDoc  } from "firebase/firestore"; 

export default function Rajyog({dob, moolang, bhagyank, name, lang, allNums}) {
    const feedbackCollection = "rajyogFeedbackData";

    const [pointsFeedback, setFeedBack] = useState([]);
    const handleFeedback = (i, j, answer) => {
        const feedback = { ...pointsFeedback, [i]: { ...pointsFeedback[i], [j]: answer } };
        delete feedback['[object Object]'];
        // console.log(feedback);
        setFeedBack(feedback);
    };

    useEffect(() => {
        const fetchDocument = async () => {
            try {
                const documentId = getUniqueId();
                const docRef = doc(db, feedbackCollection, documentId);
                const docSnap = await getDoc(docRef);
        
                if (docSnap.exists()) {
                    const { pointsFeedback } = docSnap.data();
                    console.log("Document found!!", pointsFeedback);
                    // Document exists, set its data in state
                    if (pointsFeedback !== undefined) {
                        handleFeedback({...pointsFeedback});
                    }
                    // console.log(...docSnap.data()?.pointsFeedback);
                } else {
                    console.log('Document does not exist.');
                }
            } catch (error) {
              console.error('Error fetching document: ', error);
            }
          };
      
        fetchDocument();
    }, []);

    const rajyogas = [
        [4,5,6], [2,5,8], [9,5,1], [8,1,6]
    ]

    const rajyogs = rajyogas.map((array, index) => {
        const matches = array.map((val) => {
            if (allNums.includes(val)){
                return true;
            }
            return null;
        }).filter((x) => x);
        if (matches.length === 3) {
            return rajyog[index][0];
        }

        return null;
    }).filter((x) => x);      

    useEffect(() => {
        const id = getUniqueId();
        const addFeedbackFirestore = async () => {
            try {
              await setDoc(doc(db, "rajyogFeedbackData", id), {...pointsFeedback});
            } catch (error) {
              console.error("Error adding document: ", error);
            }
          };

          addFeedbackFirestore();
    }, [pointsFeedback]);

    useEffect(() => {
        const firestoreData = {
            name: name,
            dob: dob,
            moolang: moolang,
            bhagyank: bhagyank,
            rajyogs: rajyogs,
            date: new Date()
        };

        const id = getUniqueId();
        const addDataToFirestore = async () => {
            try {
              await setDoc(doc(db, "rajyogData", id), firestoreData);
              console.log("Document written successfully");
            } catch (error) {
              console.error("Error adding document: ", error);
            }
          };

          addDataToFirestore();
    }, [name]);

const getUniqueId = () => {
    const digitsOfDOb = dob.replace(/\D/g, '');
    return digitsOfDOb+'_'+name.replace(" ", "_");
};

const checkFeedback = (i, j) => {
    if (pointsFeedback.length > 0) {
        if (pointsFeedback[i][j] !== undefined && typeof(pointsFeedback[i][j]) === 'boolean') {
            return pointsFeedback[i][j];
        }
        return null;
    }
 };

return (
    <>
        <div className='row'>
            <div className='col-md-12'>
                {rajyogs.length > 0 && (
                    rajyogs.map((yog, index) => {
                        const variant = ['light', 'light', 'light', 'dark'];
                        const {title, desc} = yog;
                        const v = variant[index];
                        return (
                            <Card
                                bg={v}
                                key={index}
                                text={v === 'light' ? 'dark' : 'white'}
                                className="mb-2"
                            >
                                <Card.Body>
                                    <h4 className="card-title">{title && title[lang]}</h4>
                                    <hr/>
                                    <Card.Text>
                                        <ListGroup as="ol" numbered style={{backgroundColor: 'transparent' }}>
                                            {desc[lang].map((point, i) => {
                                                const isFeedBackDone = checkFeedback(index, i);
                                                return (
                                                <ListGroup.Item key={i} as="li">
                                                    {point}
                                                    <span className='px-3'>
                                                        <button
                                                            type="button"
                                                            className='btn btn-xs btn-success mx-1'
                                                            onClick={() => handleFeedback(index, i, true)}
                                                            disabled={isFeedBackDone === true}
                                                        >
                                                            Yes
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className='btn btn-xs btn-danger'
                                                            onClick={() => handleFeedback(index, i, false)}
                                                            disabled={isFeedBackDone === false}
                                                        >
                                                            No
                                                        </button>
                                                    </span>
                                                </ListGroup.Item>);
                                            })}
                                        </ListGroup>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        );
                    })
                 )}
            </div>
        </div>
    </>
  )
}
