import React from 'react';
import { useIntl } from "react-intl";
import PersonalityDesc from '../helper/personality';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

export default function Personality({ name, lang }) {
    const { formatMessage } = useIntl();
    
    const numAlphaMap = {'a': 1,'b': 2,'c': 3,'d': 4,'e': 5,'f': 8,'g': 3,'h': 5,'i': 1,'j': 1,'k': 2,'l': 3,'m': 4,'n': 5,'o': 7,'p': 8,'q': 1,'r': 2,'s': 3,'t': 4,'u': 6,'v': 6,'w': 6,'x': 5,'y': 1,'z': 7}
    
    const vovels = ['a','e','i','o','u'];

    const lowerName = name.toLowerCase();

    let pNum = 0;
    for(let i = 0; i < lowerName.length; i++) {
        const ch = lowerName[i];
        if (ch === ' ' || vovels.includes(ch)) {
            continue;
        }

        pNum += numAlphaMap[ch]

    }

    while (pNum > 9) {
        pNum = pNum % 10 + Math.floor(pNum / 10);
    }
    pNum = parseInt(pNum);

  return (
    <>
        <div>
            <h3>{formatMessage({id: 'tabs.personality.heading'})}</h3>
            <p>
                {formatMessage({"id": "tabs.personality.desc"})}
            </p>
        </div>
        <div>
            <h3>{formatMessage({id: 'tabs.personality.numberTitle'})}: <span>{pNum}</span></h3>
        </div>
        {PersonalityDesc[pNum][lang]?.map((point, index) => (
            <React.Fragment key={index}>
                <Card className="mb-2">
                    <Card.Header as="h5">{point?.title}</Card.Header>
                    <Card.Body>
                        <ListGroup>
                            {point?.points?.map((i,index) => (         
                                <ListGroup.Item>{i}</ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Card.Body>
                </Card>
                <div>
                    
                </div>
                {console.log(point)}
            </React.Fragment>
        ))}
    </>
  )
}
