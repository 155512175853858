import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Your Firebase config object obtained from the Firebase Console
const firebaseConfig = {
  apiKey: "AIzaSyCWkdCT0agCxQu2olxj2PLAWhIPxPhapZ4",
  authDomain: "astro-71.firebaseapp.com",
  projectId: "astro-71",
  site: "astro71",
  storageBucket: "astro-71.appspot.com",
  messagingSenderId: "1067328550714",
  appId: "1:1067328550714:web:e759a003d72e54d0f70203",
  measurementId: "G-BJESXY0SD9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Access Firestore and Authentication
export const db = getFirestore(app);
