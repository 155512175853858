// NumerologyCalculator.js
import React, { useState } from 'react';
import { Card, Row, Col, Tab, Tabs } from 'react-bootstrap';
import Rajyog from './Rajyog';
import BasicInfo from './BasicInfo';
import { calculateMoolank, calculateNamank, getGridMatrix, calculateBhagyank } from '../helper/common';

import Grid from './Grid';
import { useIntl } from "react-intl";
import CalculatorForm from './common/CalculatorForm';
import Personality from './Personality';

const NumerologyCalculator = ({ lang }) => {

    const [name, setName] = useState('');
    const [dname, setDName] = useState('');
    const [dob, setDob] = useState('');
    const [date, setDate] = useState('');
    const [day, setDay] = useState(0);
    const [bhagyank, setBhagyank] = useState('');
    const [moolang, setMoolang] = useState('');
    const [namank, setNamank] = useState('');

    const calculateNumerology = () => {
        const d = dob.toLocaleDateString('en-IN');
        setDate(d);
        setName(dname);

        const {moolank, day} = calculateMoolank(d);

        setDay(parseInt(day));
        setMoolang(moolank);
        setBhagyank(calculateBhagyank(d));
        setNamank(calculateNamank(dname));

    };

    

    const resetForm = () => {
        setDName('');
        setDob('');
        setBhagyank('');
        setMoolang('');
    };

  const { matrix, allNums } = getGridMatrix({dob: date, moolang, bhagyank, day});
  const { formatMessage } = useIntl();
  return (
    <>
        <div className="container pt-3">
            <div className='row'>
                <div className='col-md-4'>
                    <CalculatorForm
                        resetForm={resetForm}
                        calculateNumerology={calculateNumerology}
                        dname={dname}
                        dob={dob}
                        setDName={setDName}
                        setDob={setDob}
                        showButtons={true}
                    />
                </div>
                <div className='col-md-4'>
                    {(moolang && bhagyank) && (
                        <Grid 
                            matrix={matrix}
                        />
                    )}
                </div>
                <div className='col-md-4'>
                    {(moolang && bhagyank) && (
                        <Row className="mt-3">
                            <Col className='col-12 mb-1'>
                                <Card className='text-center' bg="primary" text="light">
                                    <Card.Header >{formatMessage({id: 'infoCards.moolank'})}</Card.Header>
                                    <Card.Body>
                                        <h1 className='text-center display-3'>{parseInt(moolang)}</h1>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col className='col-12 mb-1'>
                                <Card className='text-center' bg="success" text="light">
                                    <Card.Header >{formatMessage({id: 'infoCards.bhagyank'})}</Card.Header>
                                    <Card.Body>
                                        <h1 className='text-center display-3'>{parseInt(bhagyank)}</h1>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col className='col-12 mb-1'>
                                <Card className='text-center' bg="danger" text="light">
                                    <Card.Header>{formatMessage({id: 'infoCards.namank'})}</Card.Header>
                                    <Card.Body>
                                        <h1 className='text-center display-3'>{parseInt(namank)}</h1>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    )}
                </div>
            </div>
            <div className='row mt-3'>
            {(moolang > 0 && bhagyank > 0) &&(
                <Tabs
                    defaultActiveKey="info"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    >
                    <Tab eventKey="info" title={formatMessage({id: 'tabs.info.title'})}>
                        <BasicInfo
                            dob={dob}
                            moolank={moolang}
                            bhagyank={bhagyank}
                            day={day}
                            name={name}
                            lang={lang}
                            matrix={matrix}
                            allNums={allNums}
                        />
                    </Tab>
                    <Tab eventKey="personality" title={formatMessage({id: 'tabs.personality.title'})}>
                        <Personality
                            name={name}
                            lang={lang}
                        />
                    </Tab>
                    <Tab eventKey="raj" title={formatMessage({id: 'tabs.rajyogs.title'})}>
                        <Rajyog
                            dob={date}
                            moolang={moolang}
                            bhagyank={bhagyank}
                            day={day}
                            name={name}
                            lang={lang}
                            matrix={matrix}
                            allNums={allNums}
                        />
                    </Tab>
                    <Tab eventKey="more" title="More">
                        <h1 className='text-center'>Comming Soon.!!</h1>
                        <p className='text-center'>There will soon many more similar Numerology concepts.</p>
                    </Tab>
                </Tabs>
            )}
            </div>
        </div>
    </>
  );
};

export default NumerologyCalculator;
