import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Container, Nav } from 'react-bootstrap';
import Cookies from "js-cookie";

export default function Header({setLang, lang}) {
    const languages = {
        en: 'en',
        hi: 'hi',
    };
    
    const {en, hi} = languages;

    const handleLanguage = (lang) => {
    Cookies.set('tmpl_lang', lang);
    setLang(lang);
};

  return (
    <Navbar bg="primary" data-bs-theme="dark">
        <Container>
            <Navbar.Brand href="#home">Numerology</Navbar.Brand>
            <Nav className="me-auto">
                <Nav.Link href="/" activeClassName="active">Home</Nav.Link>
                <Nav.Link to="/compatibility" as={Link} activeClassName="active">Compatibility Checker</Nav.Link>
            </Nav>
            <Nav className="me-right">
                <button className='btn btn-sm' disabled={lang === en} onClick={() => handleLanguage(en)}>EN</button>
                <button className='btn btn-sm' disabled={lang === hi} onClick={() => handleLanguage(hi)}>हिंदी</button>
            </Nav>
        </Container>
    </Navbar>
  )
}
