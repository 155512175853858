import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { useIntl, FormattedDate } from "react-intl";
import { getMyLuckyNumbers } from '../helper/common';

export default function BasicInfo({ name, dob, moolank, bhagyank }) {
  const { formatMessage } = useIntl();

  const { luckyNumbersString } = getMyLuckyNumbers(moolank, bhagyank);

  return (
    <div className='row'>
      <div className='col-md-12'>
      <Row className="mt-3">
            <Col className='col-md-2 col-sm-6 mb-1'>
                <Card className='text-center' bg="primary" text="light">
                    <Card.Header ><strong>{formatMessage({id: 'tabs.info.name'})}</strong></Card.Header>
                    <Card.Body>
                        <strong className='text-center'>{name}</strong>
                    </Card.Body>
                </Card>
            </Col>
            <Col className='col-md-2 col-sm-6 mb-1'>
                <Card className='text-center' bg="success" text="light">
                    <Card.Header><strong>{formatMessage({id: 'tabs.info.dob'})}</strong></Card.Header>
                    <Card.Body>
                        <strong className='text-center'>
                            <FormattedDate value={new Date(dob)} day="numeric" month="long" year="numeric" />
                        </strong>
                    </Card.Body>
                </Card>
            </Col>
            <Col className='col-md-2 col-sm-6 mb-1'>
                <Card className='text-center' bg="danger" text="light">
                    <Card.Header><strong>{formatMessage({id: 'tabs.info.luckyNumbers'})}</strong></Card.Header>
                    <Card.Body>
                        <strong className='text-center'>{ luckyNumbersString }</strong>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
      </div>
    </div>
  )
}
