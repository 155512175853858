import React from 'react';
import { Card } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useIntl } from "react-intl";

export default function CalculatorForm({
    dname,
    dob,
    setDName,
    setDob,
    calculateNumerology,
    resetForm,
    title,
    showButtons
    }) {
    const { formatMessage } = useIntl();

    const isInputValid = () => {
        return dname.trim() !== '' && dob instanceof Date && !isNaN(dob);
    };

    return (
        <Card className='mb-2'>
            <Card.Body>
            <h4 className='text-center'>{ formatMessage({id: title ? title : 'calculator.numerology'}) }</h4>
            <hr/>
            <div className='row'>
                <div className='col-md-12'>
                    <div className="form-group">
                        <label htmlFor="name">{ formatMessage({id: 'calculator.name'}) }:</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            value={dname}
                            onChange={(e) => setDName(e.target.value)}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label htmlFor="dob">{formatMessage({id: 'calculator.dob'})}:</label>
                        <div className='d-block'>
                            <DatePicker
                                name='dob'
                                selected={dob}
                                onChange={(date) => setDob(date)}
                                dateFormat="dd/MM/yyyy"
                                className="form-control"
                            />
                        </div>
                    </div>
                    {showButtons && (
                        <div className="d-grid gap-2 my-3 mx-auto">
                            <button type="button" disabled={!isInputValid()} className="btn btn-primary" onClick={calculateNumerology}>
                                {formatMessage({id: 'calculator.submitButton'})}
                            </button>
                            <button type="button" disabled={!isInputValid()} className="btn btn-danger" onClick=    {resetForm}>
                                {formatMessage({id: 'calculator.resetButton'})}
                            </button>
                        </div>
                    )}
                </div>
            </div>
            </Card.Body>
        </Card>
    );
}
