// GRID 1 [4,5,6]
export const rajyog1 = [
    {
      "title": {
        "en": "A Strong Support System Throughout Life",
        "hi": "जीवन भर मजबूत समर्थन प्रणाली"
      },
      "desc": {
        "en": [
          "You have the privilege of having caring and supportive parents.",
          "Your early life will be filled with abundant resources and opportunities.",
          "In school, you will make great friends who will stand by you.",
          "During college, you will have a supportive circle of friends.",
          "You will be guided by exceptional teachers and mentors who will consistently motivate and push you to excel.",
          "Your life partner will play a crucial role in supporting and encouraging your success and personal growth.",
          "You will work with understanding colleagues and have a supportive boss.",
          "Your children will be a source of continuous support throughout your life journey.",
          "Even though you have the capacity to achieve success independently, your strong support system will always be there for you."
        ],
        "hi": [
          "आपको प्यारभरे और सहयोगी माता-पिता का सौभाग्य होगा।",
          "आपके प्रारंभिक जीवन में समृद्धि से भरपूर संसाधन और अवसर होंगे।",
          "स्कूल में आप महान दोस्त बनाएंगे जो आपके साथ खड़े रहेंगे।",
          "कॉलेज के दौरान, आपके पास समर्थक मित्रों का एक समर्थनी परिपटी होगा।",
          "आपको अपूर्णिम शिक्षकों और मेंटरों के मार्गदर्शन मिलेगा जो आपको निरंतर प्रोत्साहित करेंगे और आपको उत्कृष्टता की दिशा में प्रेरित करेंगे।",
          "आपके जीवन संगी आपकी सफलता और व्यक्तिगत विकास का समर्थन करने में महत्वपूर्ण भूमिका निभाएंगे।",
          "आप समझदार सहयोगी सहयोगी के साथ काम करेंगे और आपके पास समर्थक बॉस होगा।",
          "आपके बच्चे आपके जीवन के सफर के दौरान निरंतर समर्थन का स्रोत होंगे।",
          "हालांकि आपमें यदि स्वतंत्रता से सफलता प्राप्त करने का क्षमता है, तो आपके पास हमेशा एक मजबूत समर्थन प्रणाली होगी।"
        ]
      }
    }
];

 // GRID 2 [2,5,8]
export const rajyog2 = [
    {
      "title": {
        "en": "Property, Land, or Agriculture",
        "hi": "संपत्ति, भूमि और कृषि"
      },
      "desc": {
        "en": [
          "You will enjoy happiness and prosperity through ownership of land or property.",
          "You may have been born into a family that already owns a house or property, which you inherit.",
          "With this rajyog, you will undoubtedly build your own house, so keep your determination high.",
          "At times, you may acquire property through your partner's side, but property ownership is destined for you.",
          "If you work in or are associated with real estate, agriculture, or construction-related businesses, you will find success in these fields.",
          "If you prefer not to work directly in these fields, you can invest in property for profits and gains.",
          "You might make investments in properties that yield significant returns in the future.",
          "This rajyog is associated with mining work, gemstone mining, marble, organic farming, and more.",
          "People with this rajyog often reside above the ground floor (80% of the time)."
        ],
        "hi": [
          "आप ज़मीन या संपत्ति के स्वामित्व से खुशी और समृद्धि का आनंद लेंगे।",
          "आप एक परिवार में पैदा हुए हो सकते हैं जिसमें पहले से ही घर या संपत्ति होती है, जिसे आप विरासत में पाते हैं।",
          "इस राजयोग के साथ, आप निश्चित रूप से अपना घर बनाएंगे, इसलिए अपना संकल्प ऊंचा रखें।",
          "कभी-कभी आपका संगठन द्वारा संपत्ति प्राप्त कर सकता है, लेकिन संपत्ति के स्वामित्व का तो आपके पास निर्धारित है।",
          "अगर आप निर्माण वास्तु, कृषि, या निर्माण सामग्री संबंधित काम में काम करते हैं, तो आपको इन क्षेत्रों में सफलता मिलेगी।",
          "यदि आप प्राथमिक रूप से इन क्षेत्रों में काम नहीं करना चाहते हैं, तो आप लाभ और मुनाफे के लिए संपत्ति में निवेश कर सकते हैं।",
          "आप ऐसी संपत्तियों में निवेश कर सकते हैं जो भविष्य में महत्वपूर्ण लाभ प्रदान करें।",
          "इस राजयोग के साथ खदान काम, रत्न खदान, संगमरमर, जैसे काम के साथ जुड़ा होता है।",
          "इस राजयोग वाले लोग अक्सर जमीन के ऊपर निवास करते हैं (80% समय।)"
        ]
      }
    }
];
  
// GRID 3 [9,5,1]
export const rajyog3 = [
    {
      "title": {
        "en": "Willpower and Self-Confidence",
        "hi": "इच्छाशक्ति और आत्म-विश्वास"
      },
      "desc": {
        "en": [
          "These individuals become winners through their own confidence and capabilities.",
          "They earn more money.",
          "They understand how money is created from a young age.",
          "They realize that more money can be generated through business rather than a job.",
          "They have the ability to bounce back from zero, turning 'zero to hero', even though their efforts may face setbacks.",
          "Life presents numerous ups and downs.",
          "At times, they may face betrayal or changes in government schemes that bring them back to square one.",
          "They may experience multiple cycles of ups and downs.",
          "What makes this Rajyoga special is their unwavering determination to never give up and start anew in pursuit of success.",
          "They possess exceptional willpower.",
          "Typically, they encounter more ups and downs until the age of 29-30.",
          "After the age of 30, 32, or 35, they achieve stability, happiness, and widespread recognition of their success.",
          "Early life may present struggles, but in the end, they emerge victorious.",
          "Their remarkable willpower is a divine gift.",
          "Ultimately, they contribute positively, and exceptions are those who engage in unethical practices."
        ],
        "hi": [
          "इन व्यक्तियों के अपने आत्म-विश्वास और क्षमताओं के माध्यम से वे विजेता बनते हैं।",
          "वे अधिक धन कमाते हैं।",
          "वे यह समझते हैं कि पैसा बचपन से कैसे बनता है।",
          "वे जानते हैं कि अधिक पैसा नौकरी की तुलना में व्यापार से ही बन सकता है।",
          "वे 'शून्य से हीरो' बनाने की क्षमता रखते हैं, हालांकि उनके प्रयासों को कई बार कठिनाइयों का सामना करना पड़ सकता है।",
          "जीवन में बहुत सारे ऊपर-नीचे आते हैं।",
          "कभी-कभी किसी से धोखा मिलता है या सरकारी योजनाओं में परिवर्तन होता है, जिसके कारण वे शून्य पर लौट आते हैं।",
          "वे ऊपर-नीचे के कई चक्रों का सामना कर सकते हैं।",
          "इस राजयोग को विशेष बनाने वाली बात यह है कि वे कभी हारने का इरादा नहीं करते और सफलता की पुनरारंभ के लिए न कभी हारते हैं।",
          "उनके पास अद्भुत इच्छाशक्ति है।",
          "आमतौर पर, उन्हें 29-30 की आयु तक अधिक ऊपर-नीचे के दौरे आते हैं।",
          "30, 32, या 35 की आयु के बाद, वे स्थिरता, खुशी, और अपनी सफलता की चर्चा में प्रसिद्ध होते हैं।",
          "प्रारंभिक जीवन में कठिनाइयां हो सकती हैं, लेकिन आखिरकार, वे विजयी निकलते हैं।",
          "उनकी अद्भुत इच्छाशक्ति एक दिव्य देन है।",
          "आखिरकार, वे सकारात्मक योगदान करते हैं, और विशेष बात यह है कि वे वे हैं जो अवैध/अनैतिक काम कर रहे हैं।"
        ]
      }
    }
];

 // GRID 4 [8,1,6]
export const rajyog4 = [
    {
      "title": {
        "en": "Practical Nature",
        "hi": "व्यावहारिक प्रकृति"
      },
      "desc": {
        "en": [
          "They possess a practical and rational approach to life.",
          "They understand concepts better when someone explains them with practical examples.",
          "Their practical mindset enables them to avoid unnecessary risks, and they only take calculated risks, reducing the probability of facing setbacks in life.",
          "They tend to acquire expensive gadgets and possessions.",
          "They aspire to own cars, bikes, homes, and more.",
          "They have a preference for branded items.",
          "As they age, they gradually acquire these possessions and indulge in materialistic pursuits.",
          "Life progresses well as they enjoy the fruits of their practicality."
        ],
        "hi": [
          "उनके पास जीवन में व्यावहारिक और तर्कसंगत दृष्टिकोण है।",
          "किसी भी विचारों को समझना जब कोई व्यक्तिगत उदाहरणों के साथ समझाता है, तो यह समझने में आसान हो जाता है।",
          "उनकी व्यावहारिक मानसिकता उन्हें अनावश्यक जोखिमों से बचने और केवल गणना की गई जोखिमों को लेने की क्षमता प्रदान करती है, जिससे जीवन में असफलता का संभावना कम होता है।",
          "उन्हें महंगे गैजेट्स और वस्त्रादि प्राप्त करने की प्राथमिकता है।",
          "उनकी इच्छा है कि वे कार, बाइक, घर, और अन्य कुछ का मालिक हों।",
          "वे प्राथमिकता की वस्त्रादियों की प्राथमिकता रखते हैं।",
          "जैसे-जैसे उनकी आयु बढ़ती है, वे धीरे-धीरे इन वस्त्रादियों को प्राप्त करते हैं और सामग्रिक खोजने में शामिल होते हैं।",
          "उनका जीवन व्यावहारिकता के फलस्वरूप आगे बढ़ता है जब वे अपनी व्यावहारिकता के फलों का आनंद लेते हैं।"
        ]
      }
    }
];
  
export const rajyog = [rajyog1, rajyog2, rajyog3, rajyog4];