export const gridMap = { "4":[0,0], "9": [0,1], "2": [0,2], "3": [1,0], "5": [1,1], "7": [1,2], "8": [2,0], "1": [2,1], "6": [2,2] };

export const calculateMoolank = (dob) => {
    // Extract the day part from the date of birth
    const day = dob.split('/')[0]; // Assuming the date format is 'dd/mm/yyyy'
    // Calculate Moolank by adding all the digits of the day until a single-digit number is obtained
    let moolank = day;
    while (moolank > 9) {
        moolank = moolank % 10 + Math.floor(moolank / 10);
    }
    moolank = parseInt(moolank);
    return { moolank, day };
}

export const calculateNamank = (name) => {
    const numerologyChart = {
      A: 1, B: 2, C: 3, D: 4, E: 5, F: 8, G: 3, H: 5, I: 1, J: 1, K: 2, L: 3,
      M: 4, N: 5, O: 7, P: 8, Q: 1, R: 2, S: 3, T: 4, U: 6, V: 6, W: 6, X: 5,
      Y: 1, Z: 7
    };
  
    // Convert the name to uppercase and split it into an array
    const nameArray = name.toUpperCase().split('');

    let moolang = 0;
    for (const letter of nameArray) {
      if (numerologyChart[letter]) {
        moolang += numerologyChart[letter];
      }
    }
  
    while (moolang > 9) {
      moolang = moolang % 10 + Math.floor(moolang / 10);
    }
  
    return moolang;
}

const appendDigitAsNumber = (number) => {
    if (number < 10) {
      return number * 11;
    } else {
      const lastDigit = number % 10;
      return number * 10 + lastDigit;
    }
}

export const getGridMatrix = ({ dob, moolang, bhagyank, day }) => {
    if (dob === undefined) {
        return [];
    }

    const digitsOfDOb = dob.replace(/\D/g, '');
    const digitsWithoutLeadingZeros = digitsOfDOb.replace(/^0+/, '');
    const digitArray = Array.from(digitsWithoutLeadingZeros);
    const digitMap = digitArray.map((digit) => {
        const digitValue = parseInt(digit, 10);
        if(digitValue === 0) {
            return null;
        }
        return {"cordinates": gridMap[digitValue], "digit": digitValue};
    }).filter((x) => x);

    if (day[1] !== "0" && day[0] !== "0") {
        digitMap.push({"cordinates": gridMap[moolang], "digit": moolang});
    }

    digitMap.push({"cordinates": gridMap[bhagyank], "digit": bhagyank});

    const matrix = Array(3).fill(0).map(x => Array(3).fill(0))
    let allNums = [];
    for (const {digit, cordinates} of digitMap) {
        // console.log('D-C', digit, cordinates);
        const [x, y] = cordinates ?? [0,0];
        if (matrix[x][y] !== undefined && matrix[x][y] !== 0) {
            matrix[x][y] = appendDigitAsNumber(digit);
        } else {
            matrix[x][y] = digit;
        }

        allNums.push(matrix[x][y])
    }

    return {matrix, allNums};
};

export function flattenMessages(nestedMessages, prefix = '') {
	return Object.keys(nestedMessages).reduce((messages, key) => {
		const value = nestedMessages[key];
		const prefixedKey = prefix ? `${prefix}.${key}` : key;
		if (typeof value === 'string') {
			messages[prefixedKey] = value;
		} else {
			Object.assign(messages, flattenMessages(value, prefixedKey));
		}
		return messages;
	}, {});
};

export const NUMBERS_TYPE_MAP = {
  "1": {
    "role": "KING",
    "lucky": [1,2,3,5,6,9],
    "enemy": [8],
    "neutral": [4,7]
  },
  "2": {
    "role": "QUEEN",
    "lucky": [1,2,3,5],
    "enemy": [8,4,9],
    "neutral": [6,7]
  },
  "3": {
    "role": "TEACHER",
    "lucky": [1,2,3,5,7],
    "enemy": [6],
    "neutral": [4,7,8,9]
  },
  "4": {
    "role": "MYSTIRIOUS",
    "lucky": [1,4,5,6,7,8],
    "enemy": [2,9,4,8],
    "neutral": [3]
  },
  "5": {
    "role": "KING",
    "lucky": [1,2,3,5,6,9],
    "enemy": [2,9,4,8],
    "neutral": [3]
  },
  "6": {
    "role": "Teacher(Devil)",
    "lucky": [1,5,6,7],
    "enemy": [3],
    "neutral": [2,4,8,9]
  },
  "7": {
    "role": "Saint",
    "lucky": [1,3,4,5,6],
    "enemy": [],
    "neutral": [8,2,7,9]
  },
  "8": {
    "role": "JUDGE",
    "lucky": [5,3,6,7,4,8],
    "enemy": [1,2,4,8],
    "neutral": [9]
  },
  "9": {
    "role": "COMMANDER",
    "lucky": [1,3,5],
    "enemy": [4,2],
    "neutral": [9,7,6,8]
  },
};

export const getMyLuckyNumbers = (moolank, bhagyank) => {
  const {
    lucky: moolankLuckyNumbers,
    enemy: moolankEnemyNumbers,
    neutral:moolankNeutralNumbers
  } = NUMBERS_TYPE_MAP[moolank];

  const { 
    lucky: bhagyankkLuckyNumbers,
    enemy: bhagyankEnemyNumbers,
    neutral: bhagyankNeutralNumbers
  } = NUMBERS_TYPE_MAP[bhagyank];

  const luckyNumbers = moolankLuckyNumbers.concat(bhagyankkLuckyNumbers);
  const enemyNumbers = moolankEnemyNumbers.concat(bhagyankEnemyNumbers);
  const numbersToExcluded = moolankNeutralNumbers.concat(bhagyankNeutralNumbers).concat(enemyNumbers);

  const filteredNumbers = luckyNumbers.filter(item => !numbersToExcluded.includes(item));

  const myLuckyNumbers = [...new Set(filteredNumbers)].sort((a, b) => a - b);

  return { "luckyNumbersString": myLuckyNumbers.join(", "), "luckyNumbersArray": myLuckyNumbers };
};

export const calculateBhagyank = (dob) => {
  const dobArray = dob.replace(/\D/g, '').split('');

  let bhagyank = 0;
  while (dobArray.length > 0) {
    bhagyank += parseInt(dobArray.pop(), 10);
    if (bhagyank > 9) {
        bhagyank = bhagyank % 10 + Math.floor(bhagyank / 10);
    }
  }

  return bhagyank;
};