import React, { useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { getTranslations } from './translations/index';
import { flattenMessages } from './helper/common';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Cookies from "js-cookie";

import NumerologyCalculator from './components/NumerologyCalculator';
import Compatibility from './components/Compatibility';
import Header from './components/Header';

export default function App() {

  const [messages, setMessages] = useState({});
  const language = Cookies.get("tmpl_lang") || "en";

  const [lang, setLang] = useState(language);


  useEffect(() => {
    async function fetch() {
      const asyncMessages = await getTranslations(lang);
      setMessages(asyncMessages);
    }
    fetch();
  }, [lang]);

  const translations = flattenMessages(messages);

  return (
    <>
      <IntlProvider locale={language} messages={translations}>
        <Router>
          <Header lang={lang} setLang={setLang} />
          <Routes>
            <Route exact path="/" element={<NumerologyCalculator lang={lang} />} />
            <Route exact path="/compatibility" element={<Compatibility lang={lang} />} />
          </Routes>
        </Router>
      </IntlProvider>
    </>
  )
}
