function isObject(item) {
	return (item && typeof item === 'object' && !Array.isArray(item));
}
  
function mergeDeep(target, ...sources) {
	if (!sources.length) return target;
	const source = sources.shift();
	if (isObject(target) && isObject(source)) {
		for (const key in source) {
			if (isObject(source[key])) {
				if (!target[key]) Object.assign(target, { [key]: {} });
				mergeDeep(target[key], source[key]);
			} else {
				Object.assign(target, { [key]: source[key] });
			}
		}
	}
	return mergeDeep(target, ...sources);
  }

export async function getTranslations(locale) {
	const jsonfile = await import(`./translation-${locale}.json`)

	if(locale !== 'en' && locale !== 'hi'){
		const jsonEnfile = await import('./translation-en.json')
		return mergeDeep(jsonEnfile.default , jsonfile.default )
	}
	return jsonfile.default
}
