import React, { useState } from 'react'
import CalculatorForm from './common/CalculatorForm';
import { useIntl } from "react-intl";
import { calculateMoolank, calculateNamank, getGridMatrix, calculateBhagyank } from '../helper/common';
import Grid from './Grid';


export default function Compatibility() {
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [myName, setMyName] = useState('');
    const [myDName, setMyDName] = useState('');
    const [myDob, setMyDob] = useState('');
    const [myDate, setMyDate] = useState('');
    const [myDay, setMyDay] = useState(0);

    const [partnerName, setPartnerName] = useState('');
    const [partnerDName, setPartnerDName] = useState('');
    const [partnerDob, setPartnerDob] = useState('');
    const [partnerDate, setPartnerDate] = useState('');
    const [partnerDay, setPartnerDay] = useState(0);

    const [bhagyank, setBhagyank] = useState('');
    const [moolang, setMoolang] = useState('');
    const [namank, setNamank] = useState('');

    const [partnerBhagyank, setPartnerBhagyank] = useState('');
    const [partnerMoolang, setPartnerMoolang] = useState('');
    const [partnerNamank, setPartnerNamank] = useState('');

    const calculateNumerology = () => {
        const md = myDob.toLocaleDateString('en-IN');
        setMyDate(md);
        setMyName(myDName);

        const {moolank, day} = calculateMoolank(md);

        setMyDay(parseInt(day));
        setMoolang(moolank);
        setBhagyank(calculateBhagyank(md));
        setNamank(calculateNamank(myDName));



        const pd = partnerDob.toLocaleDateString('en-IN');
        setPartnerDate(pd);
        setPartnerName(partnerDName);

        const {moolank: pmoolank, day: pday} = calculateMoolank(pd);

        setPartnerDay(parseInt(pday));

        setPartnerMoolang(pmoolank);
        setPartnerBhagyank(calculateBhagyank(pd));
        setPartnerNamank(calculateNamank(partnerDName));

        setIsFormSubmitted(true);
    };

    // console.log("My Info:", myName, myDName, myDob, myDate, myDay);
    // console.log("Partner:", partnerName, partnerDName, partnerDob, partnerDate, partnerDay);

    // console.log("M-INFO:", bhagyank, moolang, namank);
    // console.log("P-INFO:", partnerBhagyank, partnerMoolang, partnerNamank);

    const resetForm = () => {
        setMyDName('');
        setMyDob('');
        setBhagyank('');
        setMoolang('');
    };

    const { matrix } = getGridMatrix({dob: myDate, moolang, bhagyank, day: myDay});
    const { matrix: partnerMatrix } = getGridMatrix({dob: partnerDate, moolang: partnerMoolang, bhagyank: partnerBhagyank, day: partnerDay});
    
  const { formatMessage } = useIntl();

    return (
        <>
            <div className="container pt-3">
                <div className='row'>
                    <div className='col-md-12'>
                        <h3 className='display-3 text-center'>{formatMessage({id: 'compatibility.heading'})}</h3>
                        <hr/>
                    </div>
                    {!isFormSubmitted && (
                        <div className='col-md-5'>
                            <CalculatorForm
                                resetForm={resetForm}
                                calculateNumerology={calculateNumerology}
                                dname={myDName}
                                dob={myDob}
                                setDName={setMyDName}
                                setDob={setMyDob}
                                title="calculator.yourInfo"
                                showButtons={false}
                            />
                            <CalculatorForm
                                resetForm={resetForm}
                                calculateNumerology={calculateNumerology}
                                dname={partnerDName}
                                dob={partnerDob}
                                setDName={setPartnerDName}
                                setDob={setPartnerDob}
                                title="calculator.partnerInfo"
                                showButtons={true}
                            />
                        </div>
                    )}
                    <div className='col-md-4'>
                        {(moolang && bhagyank) && (
                            <Grid
                                showTitle={myName}
                                matrix={matrix}
                                className="mb-4"
                            />
                        )}
                        {(partnerMoolang && partnerBhagyank) && (
                            <Grid
                                showTitle={partnerDName}
                                matrix={partnerMatrix}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
